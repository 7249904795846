import { createSlice } from "@reduxjs/toolkit";
import { getAllRegisterAction, insertRegisterAction } from "../../actions/programs/registerAction";
import { toast } from 'react-toastify';

const INITIAL_STATE = {
    register: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },


    insertDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    }
};

const registerReducer = createSlice({
    name: "Program",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetRegisterDetailsState(state, action) {
            state.insertDetails = INITIAL_STATE.insertDetails;
        },
    },
    extraReducers: (builder) => {


        //  getAllRegisterAction action start


        builder.addCase(getAllRegisterAction.fulfilled, (state, action) => {
            state.register.data.records = action.payload;
            state.register.apiMsg.status = action.meta.requestStatus;
            state.register.apiMsg.message = "success";

        });
        builder.addCase(getAllRegisterAction.pending, (state, action) => {
            state.register.data.records = null;
            state.register.apiMsg.message = action.meta.requestStatus;
            state.register.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllRegisterAction.rejected, (state, action) => {
            // console.log("getAllRegisterAction rejected", action);
            state.register.apiMsg.message = action.error.message;
            state.register.apiMsg.status = action.meta.requestStatus;
        });
        // get all  action end


        // insert update stock adjsutement Action start

        builder.addCase(
            insertRegisterAction.fulfilled,
            (state, action) => {

                state.insertDetails.data = action.payload.data;
                state.insertDetails.apiMsg.status = "fulfilled";
                state.insertDetails.apiMsg.message = "Prenotazione creata con successo";



            }
        );
        builder.addCase(
            insertRegisterAction.pending,
            (state, action) => {
                state.insertDetails.apiMsg.status = "pending";
            }
        );
        builder.addCase(
            insertRegisterAction.rejected,
            (state, action) => {

                state.insertDetails.apiMsg.message = "Something went wrong please try again";
                state.insertDetails.apiMsg.status = "rejected";

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );


        //   insert update stock adjsutement action end
    },
});


export const { ResetRegisterDetailsState } = registerReducer.actions;
export default registerReducer.reducer;