import React from 'react'
import { Routes, Route, Navigate, Router } from "react-router-dom";
import WithLoading from './components/shared/WithLoading'
// import RouteLoader from "./components/shared/RouteLoader";

const Layout = WithLoading(React.lazy(() => import("./components/shared/layout")))

const HomePage = WithLoading(React.lazy(() => import("./components/home/home")))

const ProgramsPage = WithLoading(React.lazy(() => import("./components/programs/programs")))

const ProductPage = WithLoading(React.lazy(() => import("./components/programs/product")))

const ProductDetailPage = WithLoading(React.lazy(() => import("./components/programs/productDetail")))

const RegisterPage = WithLoading(React.lazy(() => import("./components/programs/register")))

const ContattiPage = WithLoading(React.lazy(() => import("./components/contatti/contatti")))

const ChisiamoPage = WithLoading(React.lazy(() => import("./components/chisiamo/chisiamo")))

const ConfirmationPage = WithLoading(React.lazy(() => import("./components/confirmation/confirmation")))

const PrivacyPolicyPage = WithLoading(React.lazy(() => import("./components/privacyPolicy/privacyPolicy")))


const AppRoutes = () => {

    const protectedLayout = (
        <Layout />
    )
    return (
        <Routes>


            <Route path="/" element={protectedLayout}>
                <Route index element={<HomePage />} />
            </Route>

            <Route path="/home" element={protectedLayout}>
                <Route index element={<HomePage />} />
            </Route>

            <Route path="/programs" element={protectedLayout}>
                <Route index element={<ProgramsPage />} />
            </Route>

            <Route path="/programs/product" element={protectedLayout}>
                <Route index element={<ProductPage />} />
            </Route>


            <Route path="/programs/productdetail" element={protectedLayout}>
                <Route index element={<ProductDetailPage />} />
            </Route>


            <Route path="/programs/productdetail/register" element={protectedLayout}>
                <Route index element={<RegisterPage />} />
            </Route>

            <Route path="/contatti" element={protectedLayout}>
                <Route index element={<ContattiPage />} />
            </Route>

            <Route path="/chisiamo" element={protectedLayout}>
                <Route index element={<ChisiamoPage />} />
            </Route>

            <Route path="/confirm" element={protectedLayout}>
                <Route index element={<ConfirmationPage />} />
            </Route>

            <Route path="/privacypolicy" element={protectedLayout}>
                <Route index element={<PrivacyPolicyPage />} />
            </Route>


        </Routes>
    )
}

export default AppRoutes