import axios from 'axios';
import { setLocalStore, getLocalStore, clearLocalStore } from '../localStorage/index'
const APIURL = process.env.REACT_APP_BACKEND_URL;
const LOCAL_STORAGE_KEY_USER = process.env.REACT_APP_LOCAL_STORAGE_KEY_USER;
const LOCAL_STORAGE_KEY_USER_SHOP = process.env.REACT_APP_LOCAL_STORAGE_KEY_USER_SHOP;
var headers = {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "ApiKey": "VIM||v1v!w@nd0VIM72UTLwoaBsC!uiqzv",
    "Accept-Language": getLocalStore("lang"),
}

if (getLocalStore(LOCAL_STORAGE_KEY_USER)) {

    axios.defaults.headers = {
        Accept: "application/json",
        Authorization: `Bearer ${getLocalStore(LOCAL_STORAGE_KEY_USER).tokenDetails.token}`,

    };
}
// Add a 401 response interceptor
axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (401 === error.response.status) {
        clearLocalStore(true);
        window.location = '/auth/login';
    } else {
        return Promise.reject(error);
    }
});

export const apiCall = async (path, method, data, sizesCheck) => {
    headers["Accept-Language"] = getLocalStore("lang");
    if (method === 'POST') {
        headers["Content-Type"] = 'application/json';
        headers["ApiKey"] = "VIM||v1v!w@nd0VIM72UTLwoaBsC!uiqzv";
        var res = await axios({
            method: method,
            url: sizesCheck ? path : `${APIURL}${path}`,
            headers,
            data: data
        }).then(response => {
            if (path === 'user/login') {
                if (!response.data.hasError) {
                    axios.defaults.headers = {
                        Authorization: `Bearer ${response.data.data.tokenDetails.token}`,

                    };
                    setLocalStore(LOCAL_STORAGE_KEY_USER, response.data.data);

                }

            }
            //console.log("API Response", response)
            return response;

        }).catch(error => {
            console.log("API Error", error)
            return error.response;
        })
    }
    else {


        var res = await axios({
            method: method,
            url: sizesCheck ? path : `${APIURL}${path}`,
            headers,

        }).then(response => {

            //console.log("API Response", response)
            return response;

        }).catch(error => {
            console.log("API Error", error)
            return error.response;
        })
    }

    return res;
}

export const apiUploadCall = async (path, files, setPercentage) => {
    headers["Accept-Language"] = getLocalStore("lang");
    function getFormData(object) {
        const formData = new FormData();
        Object.keys(object).forEach(key => formData.append(key, object[key]));
        return formData;
    }

    var formData = new FormData();
    // formData.append('name','test.png')
    formData.append('file', { uri: files.filePath, name: files.fileName, type: files.contentType });
    headers["Content-Type"] = 'multipart/form-data';


    const onUploadProgress = (progressEvent) => {

        console.log('progress ', (progressEvent.loaded / progressEvent.total) * 99)

        setPercentage((file) => {
            file[files.index].progress = (progressEvent.loaded / progressEvent.total) * 99;

            console.log('progressEvent: ', file[files.index])
            return [...file]
        })
        // setPercentage(99);

    }

    const data = formData;
    //console.log('formData ', formData);
    // console.log('headers', headers)


    var res = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_BACKEND_FILEUPLOAD_URL}${path}`,
        headers,
        // onUploadProgress,
        data: files,

    }).then(response => {

        console.log("API Response", response)


        return {
            ...response.data,
            file_path: `${response.data.storage}${response.data.file_path}`,

        };

    }).catch(error => {
        console.log("API Error", error)
        return error.response;
    })


    return res;
}

export const logoutUser = async () => {
    headers["Accept-Language"] = getLocalStore("lang");
    axios.defaults.headers = {

    };
    clearLocalStore(true);

    return true;
}