import React from "react";
import { Oval } from 'react-loader-spinner'


const RouteLoader = () => {
  return (<>
    <div className="route-loader">
      <Oval
        height={60}
        width={60}
        color="#FBA708"
        wrapperStyle={{}}
        wrapperclassName=""
        visible={true}
        ariaLabel='oval-loading'
        secondaryColor="#3054A0"
        strokeWidth={2}
        strokeWidthSecondary={2}

      />

    </div>

  </>);
}

export default RouteLoader;