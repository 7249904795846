import { combineReducers } from 'redux'
import programReducer from "./programs/programReducer";
import productDetailReducer from "./programs/productDetailReducer"
import registerReducer from './programs/registerReducer';
import homeReducer from './home/homeReducer';
import contactReducer from './contact/contactReducer';
import privacyPolicyReducer from './privacyPolicy/privacyPolicyReducer';
import whoWeAreReducer from './whoWeAre/whoWeAreReducer';
const reducer = {
  programReducer,
  productDetailReducer,
  registerReducer,
  homeReducer,
  contactReducer,
  privacyPolicyReducer,
  whoWeAreReducer
}


const appReducer = combineReducers(reducer);


const rootReducer = (state, action) => {
  //console.log("state",state);
  // console.log("action",action);
  if (action.type === 'auth/logout/fulfilled') {
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}


export default rootReducer;
// export default combineReducers(reducer);

export function CLEARSTORE() {
  return {
    type: "CLEARSTORE"
  };
}