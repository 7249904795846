import React from "react"
import AppRoutes from "./app.routes"
import './App.css';



const App = () => {
  return (


    <AppRoutes />

  )
}

export default App



