import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";



const fetchJson = () => {
    fetch(`${process.env.PUBLIC_URL}/localization.json`)
        .then(response => {
            return response.json();
        }).then(data => {
            i18n.use(LanguageDetector).init({
                // we init with resources
                resources: data.labels,
                fallbackLng: data.fallbackLng,
                debug: true,
                lng: data.defaultLanguage,
                // have a common namespace used around the full app
                ns: ["translations"],
                defaultNS: "translations",
                keySeparator: false, // we use content as keys
                interpolation: {
                    escapeValue: false, // not needed for react!!
                    formatSeparator: ","
                },
                react: {
                    wait: true
                }
            });;
            console.log("data", data)
        }).catch((e) => {
            console.log(e);
        });
}

fetchJson();

export default i18n;



