import { getAllContactAction } from "../../actions/contact/contactAction";
import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';

const INITIAL_STATE = {
    contact: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
}


const contactReducer = createSlice({
    name: "Contact",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetConatctDetailsState(state, action) {
        },
    },
    extraReducers: (builder) => {
        //  getAllContactAction action start
        builder.addCase(getAllContactAction.fulfilled, (state, action) => {
            console.log("getAllContactAction", action);
            state.contact.data.records = action.payload;
            state.contact.apiMsg.status = action.meta.requestStatus;
            state.contact.apiMsg.message = "success";
            console.log("state.contact.data.records", state.contact.data.records)
        });
        builder.addCase(getAllContactAction.pending, (state, action) => {
            // console.log("getAllContactAction pending", action);
            state.contact.data.records = null;
            state.contact.apiMsg.message = action.meta.requestStatus;
            state.contact.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllContactAction.rejected, (state, action) => {
            // console.log("getAllContactAction rejected", action);
            state.contact.apiMsg.message = action.error.message;
            state.contact.apiMsg.status = action.meta.requestStatus;
        });
        // get all  action end
    },
});


export const { } = contactReducer.actions;
export default contactReducer.reducer;