import { createSlice } from "@reduxjs/toolkit";
import { getAllPrivacyPolicyAction } from "../../actions/privacyPolicy/privacyPolicyAction";

const INITIAL_STATE = {
    privacyPolicy: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
}
const privacyPolicyReducer = createSlice({
    name: "PrivacyPolicy",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state = INITIAL_STATE;
        },

    },
    extraReducers: (builder) => {



        //  getAllPrivacyPolicyAction action start


        builder.addCase(getAllPrivacyPolicyAction.fulfilled, (state, action) => {
            state.privacyPolicy.data.records = action.payload;
            state.privacyPolicy.apiMsg.status = action.meta.requestStatus;
            state.privacyPolicy.apiMsg.message = "success";
           
        });
        builder.addCase(getAllPrivacyPolicyAction.pending, (state, action) => {
            state.privacyPolicy.data.records = null;
            state.privacyPolicy.apiMsg.message = action.meta.requestStatus;
            state.privacyPolicy.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllPrivacyPolicyAction.rejected, (state, action) => {
            state.privacyPolicy.apiMsg.message = action.error.message;
            state.privacyPolicy.apiMsg.status = action.meta.requestStatus;
        });
        // getAllPrivacyPolicyAction end


    },
});


export const { RESET } = privacyPolicyReducer.actions;
export default privacyPolicyReducer.reducer;