import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../../API";

export const getAllProductDetailAction = createAsyncThunk(

    "ProductDetal/GetAllProductDetail",
    async (data) => {
      return apiCall("activity/list", "POST",data, false)
        .then((response) => {
          if (response.status === 200) {
            if (!response.data.hasError) {
            
              return {
                ...response.data,
              }
            } else {
              // console.log("response error", response.data.message);
              return Promise.reject({
                ...response.data.message,
              });
            }
          }
        })
        .catch((error) => {
          return Promise.reject({
            ...error,
          });
        });
    }
  );

  export const getAllProductDetailSearchAction = createAsyncThunk(

    "ProductDetal/GetAllProductDetailSearch",
    async (data) => {
      return apiCall("activity/option", "POST",data, false)
        .then((response) => {
          if (response.status === 200) {
            if (!response.data.hasError) {
              return {
                ...response.data,
              }
            } else {
              // console.log("response error", response.data.message);
              return Promise.reject({
                ...response.data.message,
              });
            }
          }
        })
        .catch((error) => {
          return Promise.reject({
            ...error,
          });
        });
    }
  );

  export const getAllProductCartAction = createAsyncThunk(

    "ProductDetal/GetAllProductCart",
    async (data) => {
      return apiCall("activity/addtocart", "POST",data, false)
        .then((response) => {
          if (response.status === 200) {
            if (!response.data.hasError) {
              return {
                ...response.data,
              }
            } else {
              // console.log("response error", response.data.message);
              return Promise.reject({
                ...response.data.message,
              });
            }
          }
        })
        .catch((error) => {
          return Promise.reject({
            ...error,
          });
        });
    }
  );

