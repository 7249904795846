import { createSlice } from "@reduxjs/toolkit";
import { getAllHomeAction, newsletterAction, locationAction } from "../../actions/home/homeAction";
import { getLocalStore } from '../../../localStorage/index';
const INITIAL_STATE = {
    home: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    newsletter: {

        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },

    },

    location: {

        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },

    },
    selectedLang: getLocalStore("lang") ? getLocalStore("lang") : null
};

const homeReducer = createSlice({
    name: "Home",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetEmailState(state, action) {
            state.email = INITIAL_STATE.email;
        },
        SetSelectedLang(state, action) {
            state.selectedLang = action.payload.lang;
        }
    },
    extraReducers: (builder) => {



        //  getAllHomeAction action start


        builder.addCase(getAllHomeAction.fulfilled, (state, action) => {
            state.home.data.records = action.payload;
            state.home.apiMsg.status = action.meta.requestStatus;
            state.home.apiMsg.message = "success";
        });
        builder.addCase(getAllHomeAction.pending, (state, action) => {
            state.home.data.records = null;
            state.home.apiMsg.message = action.meta.requestStatus;
            state.home.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllHomeAction.rejected, (state, action) => {
            state.home.apiMsg.message = action.error.message;
            state.home.apiMsg.status = action.meta.requestStatus;
        });
        // getAllHomeAction end





        //  newsletterAction action start


        builder.addCase(newsletterAction.fulfilled, (state, action) => {
            state.newsletter.data = action.payload;
            state.newsletter.apiMsg.status = action.meta.requestStatus;
            state.newsletter.apiMsg.message = "success";
            // console.log("state.home.data.records", state.home.data.records)
        });
        builder.addCase(newsletterAction.pending, (state, action) => {
            // console.log("newsletterAction pending", action);
            state.newsletter.data = null;
            state.newsletter.apiMsg.message = action.meta.requestStatus;
            state.newsletter.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(newsletterAction.rejected, (state, action) => {
            // console.log("newsletterAction rejected", action);
            state.newsletter.apiMsg.message = action.error.message;
            state.newsletter.apiMsg.status = action.meta.requestStatus;
        });
        // newsletterAction end



        //  location action start


        builder.addCase(locationAction.fulfilled, (state, action) => {
            state.location.data = action.payload;
            state.location.apiMsg.status = action.meta.requestStatus;
            state.location.apiMsg.message = "success";
            // console.log("state.home.data.records", state.home.data.records)
        });
        builder.addCase(locationAction.pending, (state, action) => {
            // console.log("locationAction pending", action);
            state.location.data = null;
            state.location.apiMsg.message = action.meta.requestStatus;
            state.location.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(locationAction.rejected, (state, action) => {
            // console.log("locationAction rejected", action);
            state.location.apiMsg.message = action.error.message;
            state.location.apiMsg.status = action.meta.requestStatus;
        });
        // location  Action end
    },
});


export const { RESET, ResetEmailState, SetSelectedLang } = homeReducer.actions;
export default homeReducer.reducer;