import { createSlice } from "@reduxjs/toolkit";
import { getAllProductDetailAction, getAllProductDetailSearchAction, getAllProductCartAction } from "../../actions/programs/productDetailAction"


const INITIAL_STATE = {
    activities: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },


    options: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    cart: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
};

const productDetailReducer = createSlice({
    name: "productDetail",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetProductDetailsState(state, action) {
            state.activities = INITIAL_STATE.activities
            state.options = INITIAL_STATE.options;
        },
        ResetCartState(state, action) {
            state.options = INITIAL_STATE.options;
            state.cart = INITIAL_STATE.cart;
        },
    },
    extraReducers: (builder) => {



        // get all product Detail action start


        builder.addCase(getAllProductDetailAction.fulfilled, (state, action) => {
            // console.log("getAllProductDetailAction", action);
            state.activities.data.records = action.payload.Activities;
            state.activities.apiMsg.status = action.meta.requestStatus;
            state.activities.apiMsg.message = "success";
        });
        builder.addCase(getAllProductDetailAction.pending, (state, action) => {
            // console.log("getAllProductDetailAction pending", action);
            state.activities.data.records = null;
            state.activities.apiMsg.message = action.meta.requestStatus;
            state.activities.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllProductDetailAction.rejected, (state, action) => {
            // console.log("getAllProductDetailAction rejected", action);
            state.activities.apiMsg.message = action.error.message;
            state.activities.apiMsg.status = action.meta.requestStatus;
        });

        // get Product Detail action end



        // get all product Detail search action start

        builder.addCase(getAllProductDetailSearchAction.fulfilled, (state, action) => {
            state.options.data = action.payload;
            state.options.apiMsg.status = action.meta.requestStatus;
            state.options.apiMsg.message = "success";
        });
        builder.addCase(getAllProductDetailSearchAction.pending, (state, action) => {
            // console.log("getAllProductDetailSearchAction pending", action);
            state.options.data = null;
            state.options.apiMsg.message = action.meta.requestStatus;
            state.options.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllProductDetailSearchAction.rejected, (state, action) => {
            // console.log("getAllProductDetailSearchAction rejected", action);
            state.options.apiMsg.message = action.error.message;
            state.options.apiMsg.status = action.meta.requestStatus;
        });

        // get Product Detail search action end





        // get all product cart search action start

        builder.addCase(getAllProductCartAction.fulfilled, (state, action) => {
            state.cart.data = action.payload;
            state.cart.apiMsg.status = action.meta.requestStatus;
            state.cart.apiMsg.message = "success";
        });
        builder.addCase(getAllProductCartAction.pending, (state, action) => {
            // console.log("getAllProductCartAction pending", action);
            state.cart.data = null;
            state.cart.apiMsg.message = action.meta.requestStatus;
            state.cart.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllProductCartAction.rejected, (state, action) => {
            // console.log("getAllProductCartAction rejected", action);
            state.cart.apiMsg.message = action.error.message;
            state.cart.apiMsg.status = action.meta.requestStatus;
        });

        // get Product cart search action end


    },
});


export const { ResetProductDetailsState, ResetCartState } = productDetailReducer.actions;
export default productDetailReducer.reducer;