import { createSlice } from "@reduxjs/toolkit";
import { getAllProgramAction } from "../../actions/programs/programAction";


const INITIAL_STATE = {
    activities: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
};

const programReducer = createSlice({
    name: "Program",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetProgramDetailsState(state, action) {
            state.activities = INITIAL_STATE.activities
        },
    },
    extraReducers: (builder) => {



        // get all program action start


        builder.addCase(getAllProgramAction.fulfilled, (state, action) => {
            // console.log("getAllProgramAction", action);
            state.activities.data.records = action.payload.Activities;
            state.activities.apiMsg.status = action.meta.requestStatus;
            state.activities.apiMsg.message = "success";
        });
        builder.addCase(getAllProgramAction.pending, (state, action) => {
            // console.log("getAllProgramAction pending", action);
            state.activities.data.records = null;
            state.activities.apiMsg.message = action.meta.requestStatus;
            state.activities.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllProgramAction.rejected, (state, action) => {
            // console.log("getAllProgramAction rejected", action);
            state.activities.apiMsg.message = action.error.message;
            state.activities.apiMsg.status = action.meta.requestStatus;
        });
        // get all  action end
    },
});


export const { RESET, ResetProgramDetailsState } = programReducer.actions;
export default programReducer.reducer;