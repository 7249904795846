import { createSlice } from "@reduxjs/toolkit";
import { getAllWhoWeAreAction } from "../../actions/whoWeAre/whoWeAreAction";
import { getLocalStore } from '../../../localStorage/index';

const INITIAL_STATE = {
    whoWeAre: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedLang: getLocalStore("lang") ? getLocalStore("lang") : null
};

const whoWeAreReducer = createSlice({
    name: "WhoWeAre",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state = INITIAL_STATE;
        },
        SetSelectedLang(state, action) {
            state.selectedLang = action.payload.lang;
        }
    },
    extraReducers: (builder) => {

        //  getAllWhoWeAreAction action start
        
        builder.addCase(getAllWhoWeAreAction.fulfilled, (state, action) => {

            state.whoWeAre.data.records = action.payload;
            state.whoWeAre.apiMsg.status = action.meta.requestStatus;
            state.whoWeAre.apiMsg.message = "success";
        });
        builder.addCase(getAllWhoWeAreAction.pending, (state, action) => {
            state.whoWeAre.data.records = null;
            state.whoWeAre.apiMsg.message = action.meta.requestStatus;
            state.whoWeAre.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllWhoWeAreAction.rejected, (state, action) => {
            state.whoWeAre.apiMsg.message = action.error.message;
            state.whoWeAre.apiMsg.status = action.meta.requestStatus;
        });
        // getAllWhoWeAreAction end









    },
});


export const { RESET, SetSelectedLang } = whoWeAreReducer.actions;
export default whoWeAreReducer.reducer;