import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../../API";

export const getAllRegisterAction = createAsyncThunk(

  "Register/GetAllRegister",
  async (data) => {
    return apiCall("activity/getcart", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const insertRegisterAction = createAsyncThunk(
  "Register/insertRegister",
  async (data) => {
    return apiCall("activity/booking", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          return {
            ...response
          }
        }
        else {
          return Promise.reject({
            ...response,
          });
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
