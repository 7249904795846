import React, { Suspense, useState } from "react";
import RouteLoader from "./RouteLoader"

const WithLoading = (Component) => {
    return (props) => (
        <div>
            <Suspense fallback={<RouteLoader />}>
                <Component {...props} />
            </Suspense>
        </div>
    );
};



export default WithLoading;